
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';
    import IconClose from '~/assets/icons/close.svg?inline';
  import {
    localeChanged
  } from 'vee-validate';

  export default {
    components: {
      IconChevron,
       IconClose

    },
    data: () => ({
      email: null,
      password: null,
      isLoading: false,
      error: false
    }),
    methods: {
      async handleLogin() {
        try {
          this.isLoading = true;
          this.error = false;
          await this.$auth.loginWith('laravelPassport', {
            data: {
              username: this.email,
              password: this.password,
            }

          });
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          this.error = true;
        }

      },
      // getError(key) {
      //   return this.errors.hasOwnProperty(key) ? this.errors[key][0] : null;
      // },
    }

  }

